// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-temps-blog-temp-js": () => import("./../src/temps/blog-temp.js" /* webpackChunkName: "component---src-temps-blog-temp-js" */),
  "component---src-temps-courses-js": () => import("./../src/temps/courses.js" /* webpackChunkName: "component---src-temps-courses-js" */),
  "component---src-temps-tags-js": () => import("./../src/temps/tags.js" /* webpackChunkName: "component---src-temps-tags-js" */),
  "component---src-temps-mentors-js": () => import("./../src/temps/mentors.js" /* webpackChunkName: "component---src-temps-mentors-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dersler-js": () => import("./../src/pages/dersler.js" /* webpackChunkName: "component---src-pages-dersler-js" */),
  "component---src-pages-egitmenler-js": () => import("./../src/pages/egitmenler.js" /* webpackChunkName: "component---src-pages-egitmenler-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-studyo-js": () => import("./../src/pages/studyo.js" /* webpackChunkName: "component---src-pages-studyo-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tesekkurler-js": () => import("./../src/pages/tesekkurler.js" /* webpackChunkName: "component---src-pages-tesekkurler-js" */)
}

